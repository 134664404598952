// eslint-disable-next-line deprecate/import
import { useFlags } from "launchdarkly-react-client-sdk";

import { PlatformUpdateInProgressFeatureFlag } from "../components/AppWideModal/types";

export type TAvailableFeatureFlags = {
  // Permanent flags
  platformUpdatesInProgress: PlatformUpdateInProgressFeatureFlag;
  disableTypeToConfirmModals: boolean;

  // Feature related flags
  questionnairesV1: boolean;
  useStaffNoteInSchedulerGrid: boolean;
  updateStaffingLevelInUnitCard: boolean;
  hvPositionAsTab: boolean;
  preferenceRequirementsV2: boolean;
  holidaySchedule: boolean;
  staffingTargetLevelModalQueuesToInputPatientCount: boolean;
  incentivizedShifts: boolean;
  schedulerStaffProfile: boolean;
  schedulerStaffProfileExtraFeatures: boolean;
  schedulerGridConfigFab: boolean;
  showShiftReports: boolean;
  templatedPreferences: boolean;
  balancingTheHouseSidebar: boolean;
  clientFacingUnitConfig: boolean;

  // To deprecate and change to configs
  enableUsersToChangeTheirEmail: boolean;
  timeBasedTargetLevels: boolean;
};

export const useAppFlags = useFlags<TAvailableFeatureFlags>;

import { memo } from "react";

import { Tooltip, Typography } from "@mui/material";

import { black, white } from "~/common/theming/colors";
import { IStaffAttributeCustomSelect } from "~/routes/api/types";

import { IAttribute } from "@/api";
import { emptySx, TSx } from "@/common/types";

export const AttributeTag = memo(
  ({
    attribute,
    sx = emptySx,
    onClick,
  }: {
    onClick?: () => void;
    sx?: TSx;
    attribute: IAttribute | IStaffAttributeCustomSelect;
  }) => (
    <Tooltip placement="top" title={attribute.name} enterTouchDelay={0} key={attribute.name}>
      <Typography
        className="m7-attribute-tag"
        onClick={onClick}
        mx={0.2}
        px={1}
        borderRadius={2}
        variant="small"
        fontSize={10}
        fontWeight={700}
        sx={{
          ml: 1,
          backgroundColor: white,
          border: `1px solid ${black}`,
          borderRadius: "3px",
          padding: "2px 4px",
          ...sx,
        }}
        children={attribute.abbreviation}
      />
    </Tooltip>
  ),
);

import { brandedAlias } from "~/common/types";
import { IUnitBasic } from "~/routes/api/types";

import { BULK_DELETE_KEY, BULK_UPDATE_KEY } from "@/common/constants";

import { APIListResponse } from "../_shared/types";

export type IAttribute = Attribute.DTO;

export type AttributeKey = brandedAlias<string>;

export namespace Attribute {
  export interface DTO {
    abbreviation: string;
    group: string;
    key: AttributeKey;
    name: string;
    unitId: IUnitBasic["id"];
    usedForShiftOverview: boolean;
  }
  export namespace API {
    const PATH_ROOT = "/attribute";
    export namespace BulkSave {
      export const PATH = PATH_ROOT + "/bulk-save";
      export interface QueryParams {
        attributes: (TAttributeToUpdate | TAttributeToCreate | TAttributeToDelete)[];
      }
      export type Response = APIListResponse<DTO>;
    }
  }
}

type TAttributeToUpdate = Partial<Attribute.DTO> & {
  key: AttributeKey;
  [BULK_UPDATE_KEY]: boolean;
};
type TAttributeToCreate = Omit<Attribute.DTO, "key">;
type TAttributeToDelete = {
  key: AttributeKey;
  [BULK_DELETE_KEY]: boolean;
};

import { Second, ShiftType, TimeString, toISO, Uuid } from "@m7-health/shared-utils";
import { PayloadAction } from "@reduxjs/toolkit";

import { IUnitShiftType } from "@/api";
import { KeyBy } from "@/common/types";

import { THouseViewState, TModalState } from ".";

export type TShiftTypeState = {
  toUpdate: Record<IUnitShiftType["id"], IUnitShiftType>;
  toDelete: Record<IUnitShiftType["id"], IUnitShiftType>;
  toCreate: Record<IUnitShiftType["id"], IUnitShiftType>;
  recordsInDb: Record<IUnitShiftType["id"], IUnitShiftType>;
};

export const ShiftTypeActionsInitState: TShiftTypeState = {
  toUpdate: {},
  toDelete: {},
  toCreate: {},
  recordsInDb: {},
};

export type TShiftTypeModals = {
  manageShiftTypesOrder: TModalState;
};

export const ShiftTypeModalsInitState: TShiftTypeModals = {
  manageShiftTypesOrder: {
    isOpen: false,
  },
};

const getEmptyShiftType = (id: Uuid): Omit<IUnitShiftType, "id" | "unitId"> => ({
  key: id as unknown as ShiftType.Key, // we don't care about the key on new items, generated on the server
  createdAt: toISO(new Date()),
  updatedAt: toISO(new Date()),
  deletedAt: null,

  name: "",
  startTime: "07:00:00" as TimeString,
  durationSeconds: (60 * 60 * 12) as Second,
  displayableInDropdowns: [],
  scheduleViewDisplayName: "",
  scheduleViewColor: "#000000",
  schedulerAssignableShift: false,
  staffViewDisplayName: "",
  staffViewColor: "#000000",
  staffPreferableShift: false,

  isHouseSupervisorShift: false,
  isPatientCareShift: false,
  isWorkingShift: false,
  isPaidShift: false,
  isCountedForRequirements: false,
  isCountedForRealTimeStaffingTarget: false,

  violationIfReplacingNull: false,
  violationIfReplacedByNull: false,
  violationIfReplacedByPatientNonWorking: false,
  violationIfReplacedByNonPatientNonWorking: false,
  violationIfReplacedByPatientWorking: false,
  violationIfReplacedByNonPatientWorking: false,
});

export const ShiftTypeActions = {
  reorderShiftTypes: (
    { shiftTypes }: THouseViewState,
    { payload }: PayloadAction<IUnitShiftType[]>,
  ) => {
    payload.forEach((shiftType, index) => {
      const updatedShiftType = { ...shiftType, sortPosition: index };

      if (shiftTypes.toCreate[updatedShiftType.id]) {
        shiftTypes.toCreate[updatedShiftType.id] = updatedShiftType;
      } else if (shiftTypes.toUpdate[updatedShiftType.id]) {
        shiftTypes.toUpdate[updatedShiftType.id] = updatedShiftType;
      } else if (shiftTypes.toDelete[updatedShiftType.id]) {
        shiftTypes.toDelete[updatedShiftType.id] = updatedShiftType;
      } else {
        shiftTypes.toUpdate[updatedShiftType.id] = updatedShiftType;
      }
    });
  },

  updateShiftType: ({ shiftTypes }: THouseViewState, action: PayloadAction<IUnitShiftType>) => {
    if (shiftTypes.toCreate[action.payload.id]) {
      shiftTypes.toCreate[action.payload.id] = action.payload;
    } else if (shiftTypes.toUpdate[action.payload.id]) {
      shiftTypes.toUpdate[action.payload.id] = action.payload;
    } else if (shiftTypes.toDelete[action.payload.id]) {
      shiftTypes.toDelete[action.payload.id] = action.payload;
    } else {
      shiftTypes.toUpdate[action.payload.id] = action.payload;
    }
  },

  deleteShiftType: ({ shiftTypes }: THouseViewState, action: PayloadAction<IUnitShiftType>) => {
    if (shiftTypes.toCreate[action.payload.id]) {
      delete shiftTypes.toCreate[action.payload.id];
      return;
    } else if (shiftTypes.toUpdate[action.payload.id]) {
      delete shiftTypes.toUpdate[action.payload.id];
    }
    shiftTypes.toDelete[action.payload.id] = action.payload;
  },

  restoreShiftType: ({ shiftTypes }: THouseViewState, action: PayloadAction<IUnitShiftType>) => {
    delete shiftTypes.toDelete[action.payload.id];
    delete shiftTypes.toUpdate[action.payload.id];
    delete shiftTypes.toCreate[action.payload.id];
  },

  duplicateShiftType: ({ shiftTypes }: THouseViewState, action: PayloadAction<IUnitShiftType>) => {
    const id = window.crypto.randomUUID();
    shiftTypes.toCreate[id] = { ...action.payload, id };
  },

  createShiftType: (
    { shiftTypes }: THouseViewState,
    { payload }: PayloadAction<Pick<IUnitShiftType, "unitId">>,
  ) => {
    const id = window.crypto.randomUUID() as Uuid;
    shiftTypes.toCreate[id] = {
      id,
      unitId: payload.unitId,
      ...getEmptyShiftType(id),
    };
  },

  setDbShiftTypes: (
    { shiftTypes }: THouseViewState,
    action: PayloadAction<KeyBy<IUnitShiftType, "id">>,
  ) => {
    shiftTypes.recordsInDb = action.payload;
  },

  cancelAllShiftTypeChanges: ({ shiftTypes }: THouseViewState) => {
    shiftTypes.toCreate = {};
    shiftTypes.toDelete = {};
    shiftTypes.toUpdate = {};
  },
};
